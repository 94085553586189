import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { ITOGCharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import lodash from 'lodash';
import { TOGCharacter } from '../../modules/tog/common/components/tog-character';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    }
  }
};

interface ITogCharacterNodes {
  nodes: ITOGCharacter[];
}

interface ITogCharacterEntry {
  allCharacters: ITogCharacterNodes;
}

interface IProps {
  data: ITogCharacterEntry;
}

const TOGIgnition: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'R',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_r.png"
                height={24}
                alt="R rarity"
              />
            )
          },
          {
            value: 'SR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_sr.png"
                height={24}
                alt="SR rarity"
              />
            )
          },
          {
            value: 'SSR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssr.png"
                height={24}
                alt="SSR rarity"
              />
            )
          },
          {
            value: 'SSR+',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssrplus.png"
                height={24}
                alt="SSR+ rarity"
              />
            )
          }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_phys.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Magical',
            tooltip: 'Magical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_magic.png"
                width={24}
                alt="Magical"
              />
            )
          }
        ]
      },
      {
        key: 'color',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Yellow',
            tooltip: 'Yellow',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_yellow.png"
                width={24}
                alt="Yellow"
              />
            )
          },
          {
            value: 'Purple',
            tooltip: 'Purple',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_purple.png"
                width={24}
                alt="Purple"
              />
            )
          },
          {
            value: 'Red',
            tooltip: 'Red',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_red.png"
                width={24}
                alt="Red"
              />
            )
          },
          {
            value: 'Blue',
            tooltip: 'Blue',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_blue.png"
                width={24}
                alt="Blue"
              />
            )
          },
          {
            value: 'Green',
            tooltip: 'Green',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_green.png"
                width={24}
                alt="Green"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          },
          {
            value: 'Assassin',
            tooltip: 'Assassin',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_assassin.png"
                width={24}
                alt="Assassin"
              />
            )
          },
          {
            value: 'Ranged',
            tooltip: 'Ranged',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_ranged.png"
                width={24}
                alt="Ranged"
              />
            )
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
      if (activeFilters.color && activeFilters.color !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.color.toLowerCase() === activeFilters.color.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page tog-ignition'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>Ignite Weapons</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/tog/categories/category_ign.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Tower of God: New World Ignition Weapons</h1>
          <h2>
            Best Ignition Weapons for your Tower of God: New World characters.
          </h2>
          <p>
            Last updated: <strong>31/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Ignition Weapons" />
      <div className="igni-intro">
        <p>
          There are currently eight Ignition Weapons sets available:{' '}
          <strong>
            Bergamot (Lifesteal Set), El (ASPD Set), Myenogwol (DEF Set), Runda
            (HP Set), Doris (Swiftness/Barrier Set), Raihanna (ATK/Silence Set),
            Abgrund (Crit DMG/stealth set), Idea (Energy set), Sundance (ACC
            set) and Sela (DEF/Res set).
          </strong>{' '}
          and below you will find suggestions for every character which set
          helps them more and what Main Stats you should use on Slot 4 and Slot
          5 pieces.
        </p>
        <p>
          The suggestions were created with the help of <strong>Conowen</strong>{' '}
          who shared them with us. You can find his YouTube channel{' '}
          <a
            href="https://www.youtube.com/@Conowen96"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and his discord{' '}
          <a
            href="discord.com/invite/gVDnRNa6ey"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . On his channel, Conowen is posting reviews for every new character,
          guides for bosses and he even does account reviews on his Discord. Do
          check him out!
        </p>
      </div>
      <div className="employees-filter-bar nikke">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="ignition-sheet">
        <div className="ignition-row header">
          <div className="character">Character</div>
          <div className="set-1">Best Set</div>
          <div className="set-2">Alternative Set</div>
          <div className="stats">Slot 4 Main Stat</div>
          <div className="stats">Slot 5 Main Stat</div>
          <div className="notes">Notes</div>
        </div>
        {visibleCharacters.map((emp, index) => {
          return (
            <div className="ignition-row" key={index}>
              <div className="character">
                <Card className="avatar-card">
                  <TOGCharacter
                    slug={emp.slug}
                    mode="icon"
                    showLabel
                    enablePopover
                  />
                </Card>
              </div>
              <div className="set-1">
                <p className="on-mobile">Best Set</p>
                {emp.ignition1 === 'Bergamot' && (
                  <div className="set berg">
                    <StaticImage
                      src="../../images/tog/icons/set_berga.png"
                      alt="Bergamot"
                    />
                    <span>Bergamot</span>
                  </div>
                )}
                {emp.ignition1 === 'El' && (
                  <div className="set el">
                    <StaticImage
                      src="../../images/tog/icons/set_el.png"
                      alt="El"
                    />
                    <span>El</span>
                  </div>
                )}
                {emp.ignition1 === 'Runda' && (
                  <div className="set runda">
                    <StaticImage
                      src="../../images/tog/icons/set_runda.png"
                      alt="Runda"
                    />
                    <span>Runda</span>
                  </div>
                )}
                {emp.ignition1 === 'Myeon' && (
                  <div className="set mye">
                    <StaticImage
                      src="../../images/tog/icons/set_mye.png"
                      alt="Myeon"
                    />
                    <span>Myeon</span>
                  </div>
                )}
                {emp.ignition1 === 'Doris' && (
                  <div className="set doris">
                    <StaticImage
                      src="../../images/tog/icons/set_doris.png"
                      alt="Doris"
                    />
                    <span>Doris</span>
                  </div>
                )}
                {emp.ignition1 === 'Raihanna' && (
                  <div className="set raiha">
                    <StaticImage
                      src="../../images/tog/icons/set_raiha.png"
                      alt="Raihanna"
                    />
                    <span>Raihanna</span>
                  </div>
                )}
                {emp.ignition1 === 'Abgrund' && (
                  <div className="set abgrund">
                    <StaticImage
                      src="../../images/tog/icons/set_7.png"
                      alt="Abgrund"
                    />
                    <span>Abgrund</span>
                  </div>
                )}
                {emp.ignition1 === 'Idea' && (
                  <div className="set idea">
                    <StaticImage
                      src="../../images/tog/icons/set_8.png"
                      alt="Idea"
                    />
                    <span>Idea</span>
                  </div>
                )}
                {emp.ignition1 === 'Sundance' && (
                  <div className="set sundance">
                    <StaticImage
                      src="../../images/tog/icons/set_sundance.png"
                      alt="Sundance"
                    />
                    <span>Sundance</span>
                  </div>
                )}
                {emp.ignition1 === 'Sela' && (
                  <div className="set sela">
                    <StaticImage
                      src="../../images/tog/icons/set_sela.png"
                      alt="Sela"
                    />
                    <span>Sela</span>
                  </div>
                )}
                {emp.ignition1 === 'Alocasia' && (
                  <div className="set alocasia">
                    <span>Alocasia</span>
                  </div>
                )}
                {emp.ignition1 === 'Rafflesia' && (
                  <div className="set rafflesia">
                    <span>Rafflesia</span>
                  </div>
                )}
                {!emp.ignition1 && <span className="center">-</span>}
              </div>
              <div className="set-2">
                <p className="on-mobile">Alternative Set</p>
                {emp.ignition2 === 'Bergamot' && (
                  <div className="set berg">
                    <StaticImage
                      src="../../images/tog/icons/set_berga.png"
                      alt="Bergamot"
                    />
                    <span>Bergamot</span>
                  </div>
                )}
                {emp.ignition2 === 'El' && (
                  <div className="set el">
                    <StaticImage
                      src="../../images/tog/icons/set_el.png"
                      alt="El"
                    />
                    <span>El</span>
                  </div>
                )}
                {emp.ignition2 === 'Runda' && (
                  <div className="set runda">
                    <StaticImage
                      src="../../images/tog/icons/set_runda.png"
                      alt="Runda"
                    />
                    <span>Runda</span>
                  </div>
                )}
                {emp.ignition2 === 'Myeon' && (
                  <div className="set mye">
                    <StaticImage
                      src="../../images/tog/icons/set_mye.png"
                      alt="Myeon"
                    />
                    <span>Myeon</span>
                  </div>
                )}
                {emp.ignition2 === 'Doris' && (
                  <div className="set doris">
                    <StaticImage
                      src="../../images/tog/icons/set_doris.png"
                      alt="Doris"
                    />
                    <span>Doris</span>
                  </div>
                )}
                {emp.ignition2 === 'Raihanna' && (
                  <div className="set raiha">
                    <StaticImage
                      src="../../images/tog/icons/set_raiha.png"
                      alt="Raihanna"
                    />
                    <span>Raihanna</span>
                  </div>
                )}
                {emp.ignition2 === 'Idea' && (
                  <div className="set idea">
                    <StaticImage
                      src="../../images/tog/icons/set_8.png"
                      alt="Idea"
                    />
                    <span>Idea</span>
                  </div>
                )}
                {emp.ignition2 === 'Sundance' && (
                  <div className="set sundance">
                    <StaticImage
                      src="../../images/tog/icons/set_sundance.png"
                      alt="Sundance"
                    />
                    <span>Sundance</span>
                  </div>
                )}
                {emp.ignition2 === 'Sela' && (
                  <div className="set sela">
                    <StaticImage
                      src="../../images/tog/icons/set_sela.png"
                      alt="Sela"
                    />
                    <span>Sela</span>
                  </div>
                )}
                {emp.ignition2 === 'Alocasia' && (
                  <div className="set alocasia">
                    <span>Alocasia</span>
                  </div>
                )}
                {emp.ignition2 === 'Rafflesia' && (
                  <div className="set rafflesia">
                    <span>Rafflesia</span>
                  </div>
                )}
                {!emp.ignition2 && <span className="center">-</span>}
              </div>
              <p className="on-mobile">Slot 4 Main Stat</p>
              <div className="stats">
                {emp.slot4 ? emp.slot4 : <span>-</span>}
              </div>
              <p className="on-mobile">Slot 5 Main Stat</p>
              <div className="stats">
                {emp.slot5 ? emp.slot5 : <span>-</span>}
              </div>
              <p className="on-mobile">Notes</p>
              <div className="notes">
                {emp.slotNotes ? (
                  <>{renderRichText(emp.slotNotes, options)}</>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage src="../../images/tog/no_results.png" alt="No results" />
          <p>No characters found. Try changing your filters.</p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default TOGIgnition;

export const Head: React.FC = () => (
  <Seo
    title="Ignition Weapons | Tower of God: New World | Prydwen Institute"
    description="Best Ignition Weapons for your Tower of God: New World characters."
    game="tog"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulTogCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        color
        type
        class
        ignition1
        ignition2
        slotNotes {
          raw
        }
        slot4
        slot5
        rarity
        notReleased
      }
    }
  }
`;
